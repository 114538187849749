<template>
    <app-container :type="0"> </app-container>
</template>

<script>
import AppContainer from '@/components/app/app-container';

export default {
    name: 'Index',
    components: { AppContainer },
    data: () => ({
        loading: '',
        openedApps: [], // code, url
        currentAppCode: ''
    }),
    methods: {
        showLoading() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'isc-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
        }
    }
};
</script>

<style scoped lang="scss"></style>
