<template>
    <div class="app__container">
        <template v-for="item in openedApps">
            <div v-show="item.code === currentAppCode" :key="item.code" class="app__container__iframes">
                <isc-container
                    v-show="item.code === currentAppCode && item.outsideAppTag"
                    class="align-center justify-center"
                    style="background-color: white; height: 100%"
                >
                    <span>请在新窗口中查看应用</span>
                </isc-container>
                <iframe
                    @load="onLoad(item)"
                    v-show="item.loaded && item.code === currentAppCode && !item.outsideAppTag"
                    class="app__container__iframe"
                    height="100%"
                    width="100%"
                    :src="computedUrl(item.url)"
                    allowfullscreen
                ></iframe>
                <div v-if="!item.loaded" v-loading="!item.loaded" style="width: 100%; height: 100%"></div>
            </div>
        </template>
    </div>
</template>

<script>
import { desk } from '@isyscore/messenger';
export default {
    name: 'AppContainer',
    data() {
        return {
            currentAppCode: '',
            openedApps: []
        };
    },
    created() {
        this.initStorage();
        this.$appManager.recover();
    },
    mounted() {
        this.$appManager.on('current-app-change', this.onCurrentAppChange);
        this.$appManager.on('opened-app-change', this.onAppListChange);
    },
    beforeDestroy() {
        this.$appManager.off('current-app-change', this.onCurrentAppChange);
        this.$appManager.off('opened-app-change', this.onAppListChange);
    },
    methods: {
        computedUrl(url) {
            if (url.indexOf('?token') !== -1 || url.indexOf('&token') !== -1) {
                // 有 token
                var re = /(token)([^&]*)/gi;
                var newUrl = url.replace(re, 'token=' + window.localStorage.getItem('ISC_SSO_TOKEN'));
                return newUrl;
            }
            if (url.indexOf('?') === -1) {
                url += `?token=${window.localStorage.getItem('ISC_SSO_TOKEN')}`;
            } else {
                url += `&token=${window.localStorage.getItem('ISC_SSO_TOKEN')}`;
            }
            return url;
        },

        initStorage() {
            try {
                this.openedApps = JSON.parse(desk.sessionStorage.getItem('openedApps'));
            } catch (e) {
                console.error(e);
                this.openedApps = [];
            }
            this.currentAppCode = desk.sessionStorage.getItem('currentApp') || '';
        },
        onCurrentAppChange(ev) {
            this.currentAppCode = ev;
        },
        onAppListChange(ev) {
            this.openedApps = ev;
        },
        onLoad(app) {
            for (let i = 0; i < this.openedApps.length; i++) {
                if (this.openedApps[i].code === app.code) {
                    app.loaded = true;
                    this.$set(this.openedApps, i, app);
                    break;
                }
            }
            this.$appManager.appLoaded(app.code);
        }
    }
};
</script>

<style scoped lang="scss">
.app__container {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    &__iframes {
        width: 100%;
        height: 100%;
        background: #fff;
    }
    &__iframe {
        border: none;
        background: white;
    }
}
</style>
